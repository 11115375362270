import Image from 'next/image';
import Link from 'next/link';

import { useState } from 'react';

import MenuData from '../../data/MegaMenu';

import CourseLayout from './NavProps/CourseLayout';
import ElementsLayout from './NavProps/ElementsLayout';
import PageLayout from './NavProps/PageLayout';

import addImage from '../../public/images/service/mobile-cat.jpg';

const Nav = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const toggleMenuItem = (item) => {
    setActiveMenuItem(activeMenuItem === item ? null : item);
  };

  return (
    <nav className="mainmenu-nav  pt_lg--100">
      <ul className="mainmenu">
        <li className="with-megamenu has-menu-child-item"></li>
        <li className="has-dropdown has-menu-child-item"></li>
        <li className="with-megamenu has-menu-child-item position-static"></li>
        <li className="with-megamenu has-menu-child-item position-static"></li>
        <li className="with-megamenu has-menu-child-item position-static"></li>
      </ul>
    </nav>
  );
};
export default Nav;
